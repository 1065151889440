<template>
    <v-dialog v-model="dialog" persistent max-width="600px"  @input="openedHandler">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5"> {{ $t('edit routes') }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="data.name" type="text" :label="$t('name')" :hint="$t('requird')"
                                required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="data.price" type="number" :label="$t('price')" :hint="$t('requird')"
                                required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete v-model="data.driver_id"    item-text="name" item-value="user_id" :items="items" 
                                
                                :loading="driverRoute.driverNameState.loading" :label="$t('driver name')"
                                :hint="$t('requird')" ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="blue darken-1" text @click="dialog = false">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn rounded :color="btnColor" text @click="setData">
                    <span v-if="!driverRoute.editRouteState.loading"> {{ $t('edit') }}</span>
                    <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { driver } from '../../../store/drivers'
import { driverRoute } from '../../../store/driverRoutes'
import notify from '../../../helpers/toast-notifications'
import axios from 'axios'


export default {
    props: {
        typeTrip: String,
        item: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            driver,
            driverRoute,
            dialog: false,
            data: {
                name: null,
                price: null,
                driver_id: null
            },
            driverSearch: null,
            items:[],
        }
    },
    methods: {
        setData() {
            if (this.btnColor == 'blue') {
                let data = {
                    ...this.data,
                    type: this.typeTrip,
                }
                driverRoute.editRoute(data, this.item.id)
                this.dialog = driverRoute.editRouteState.loading
                this.dialog = false
            }
            else {
                notify(
                    this.$t('please complete one info at least'),
                    { timeout: 4000, rtl: true },
                    "error"
                );

            }
        },
       async openedHandler(v){
            if(v){
                const res = await axios.get(`/driver`);
                this.items = res.data.drivers;
                console.log(this.item);
                this.data.name = this.item.name;
                this.data.price = this.item.price;
                this.data.driver_id = this.item.driver.user_id;
                // for(let i=0;i<this.items.length;i++){
                //     if(this.items.id==this.data.driver_id){
                    
                //     }
                // }
                
                
                
            }
        },

    },
    computed: {
        
        btnColor() {
            if ((this.data.name == null | this.data.name == '') && (this.data.price == null | this.data.price == '') && (this.data.driver_id == null | this.data.driver_id == '')) {
                return 'gray'
            } else {
                return 'blue'
            }
        }
    },
    watch: {
        driverSearch(val) {
            if (val != '') {
                driverRoute.storeDriverName(val);
                this.items = driverRoute.driver;
            } else {
                driverRoute.driver = []
            }

        }
    },
    
}
</script>