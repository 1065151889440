<template>
    <v-dialog v-model="dialog" persistent max-width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue" v-bind="attrs" v-on="on" @click="fetchData" >mdi-pen</v-icon>
        </template>
        <v-card>
            <v-card-title class="text-h5" style="color: red; ">
                {{ $t('Edit Route Info') }}
            </v-card-title>
            <v-card-text>
                <v-container>
            <v-row>
                <v-col md="1"></v-col>
                <v-col md="4">
                    <v-text-field  v-model="order" :label="$t('Ordering')" type="number" >
                    </v-text-field>
                </v-col>
                <v-col md="4">   
                        <v-text-field v-model="time" :label="$t('Time To Arrive')" type="number" >
                        </v-text-field>                    
                </v-col >
                <v-col class="mt-5">
                    <h3 color="var(--bg-color--font)" >{{ $t('minute') }}</h3>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                            <v-text-field  v-model="lng"
                                
                                :label="$t('Longitude')" ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field  v-model="lat"
                               
                                :label="$t('Latitude')" ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="building_location" :label="$t('location explain')" type="text"
                                 required></v-text-field>
                        </v-col>
                        <v-col  cols="12" sm="6" md="6">
                            <my-map :positionLabel="labelPosition" @position="getPosition($event)"></my-map>
                        </v-col>
            </v-row>
        </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="blue" text @click="dialog = false">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn rounded color="blue" text @click="editstudent">
                <span v-if="!driverRoute.deleteRouteState.loading"> {{ $t('edit') }}</span>
                <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { driverRoute } from '../../../store/driverRoutes'
import map from './mapComponent.vue'
export default {
    components: {
        myMap: map
    },
    props: {
        item: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    data() {
        return {
            driverRoute,
            order: null,
            time: null,
            lng: null,
            lat: null,
            building_location: null,
            dialog: driverRoute.deleteRouteState.loading,
            r_id: driverRoute.routeInfoState.value.data.id,

        }
    },
    computed: {
        labelPosition() {
            if (this.lng == null && this.lng == null) {
                console.log(this.lng)
                return 'click to add location on the map'
            } else {
                console.log(this.lng)
                return 'The site has been determined'
            }
        },
    },
    methods: { 
        getPosition(e) {
            let result = JSON.parse(e);
            this.lat = result.lat
            this.lng = result.lng
            localStorage.removeItem('position')
            console.log(this.data)
        },
        async fetchData(){
            let res = await axios.get(`/student_route/${this.item.id}`);
            let data = res.data.data;
            this.order = data.order;
            this.time = data.time;
            this.lng = data.lng;
            this.lat = data.lat;
            this.building_location = data.building_location;
        },
       async editstudent() {    
           this.lng = String(this.lng);
           this.lat = String(this.lat);
        let res = await axios.put(`/student_route/${this.item.id}`,{
            order: this.order,
            time: this.time,
            lng: this.lng,
            lat: this.lat,
            building_location: this.building_location,
        });
           if(res.status==200){
            this.$Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
           } 
           this. driverRoute.getStudentsInRoute({route_id : this.r_id});
           //this.$router.go(-1);
           this.dialog=false;
           
           
       
        }
    }
}
</script>