<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <v-card>
            <v-card-title class="text-h5" style="color: red;">
                {{ $t('are you sure you want delete') }}؟
            </v-card-title>

            <v-spacer></v-spacer>
            <v-btn rounded color="blue" text @click="dialog = false">
                {{ $t('cancel') }}
            </v-btn>
            <v-btn rounded color="blue" text @click="deleteDriver">
                <span v-if="!driver.deleteDriverState.loading"> {{ $t('submit') }}</span>
                <v-progress-circular v-else :width="3" color="blue" indeterminate></v-progress-circular>
            </v-btn>
        </v-card>
    </v-dialog>
</template>
<script>
import { driver } from '../../../store/drivers'
export default {
    props: ['item'],
    data() {
        return {
            driver,
            dialog: driver.deleteDriverState.loading,
        }
    },
    methods: {
        deleteDriver() {
            driver.deleteDriver(this.item.id)
        }
    }
}
</script>